import * as createMatrix from "./createMatrix";
import { serverTimestamp } from "firebase/firestore";


export const generateMutualMatchQueue = async (movers, sitters, eventData) => {
  const eventUsers = Object.values(eventData.eventUsers);
  const { consentMatrix, queueMatrix, matchedAttendees } = createMatrices(eventUsers, sitters, movers);

  const mappedProfilesData = mapProfiles(matchedAttendees.movers, matchedAttendees.sitters, eventUsers);

  return {
    queueMatrix,
    consentMatrix,
    movers: mappedProfilesData.movers,
    sitters: mappedProfilesData.sitters,
    at: serverTimestamp()
  };
};


export const regenerateMutualMatchQueue = async (event, currentRound = 0, eventData) => {
  const eventUsers = Object.values(eventData.eventUsers);
  // Reconstruct the old matrices from the event's queue
  const oldConsentMatrix = createMatrix.reconstructMatrix(event.queue.consentMatrix);
  const oldQueueMatrix = createMatrix.reconstructMatrix(event.queue.queueMatrix);

  const removeUnmatchedUsers = createMatrix.removeUnmatchedUsers(
    eventUsers,
    event.sitters,
    event.movers
  )

  const existingSittersIds = event.queue.sitters.map(u => u.userID)
  const existingMoversIds = event.queue.movers.map(u => u.userID)

  const incomingSitters = removeUnmatchedUsers.sitters.filter(uid => !existingSittersIds.includes(uid))
  const incomingMovers = removeUnmatchedUsers.movers.filter(uid => !existingMoversIds.includes(uid))


  const newSitters = [...existingSittersIds, ...incomingSitters]
  const newMovers = [...existingMoversIds, ...incomingMovers]

  // Create new consent data based on updated eventUsers, sitters, and movers
  const newConsentData = createMatrix.createConsentMatrix(eventUsers, newSitters, newMovers);

  // Compute the difference between old and new consent matrices
  const newConsentMatrixDiff = createMatrix.matrixDiff(oldConsentMatrix, newConsentData.consentMatrix);



  if (newConsentMatrixDiff.length > 0) {

    // Remove duplicates to get the new queue differences
    const newMatrixDiffQueue = createMatrix.generateQueueFromConsent(newConsentMatrixDiff);
    // Adjust columns of the old queue matrix based on the adjusted round
    const slicedTopOldQueueMatrix = createMatrix.adjustColumns(
      oldQueueMatrix.slice(0, currentRound),
      newConsentData.consentMatrix
    );

    const slicedBottomOldQueueMatrix = createMatrix.adjustColumns(oldQueueMatrix.slice(currentRound), newConsentData.consentMatrix);
    // Combine the adjusted old matrix with the new differences
    const combinedMatrix = [
      ...slicedBottomOldQueueMatrix,
      ...newMatrixDiffQueue
    ];

    // Remove duplicates from the combined matrix to form the new queue matrix
    const newQueueMatrix = createMatrix.generateQueueFromConsent(combinedMatrix);

    // Merge the top part of the old matrix with the new queue matrix
    const mergedQueueMatrix = [
      ...slicedTopOldQueueMatrix,
      ...newQueueMatrix
    ];

    // Flatten the merged queue matrix for further processing
    const queueMatrix = createMatrix.flattenMatrix(mergedQueueMatrix);

    // Flatten the new consent matrix
    const consentMatrix = createMatrix.flattenMatrix(newConsentData.consentMatrix);

    // Extract the mover and sitter IDs from the new consent data
    const newMovers = newConsentData.movers;
    const newSitters = newConsentData.sitters;

    // Map the profiles using the helper function
    const mappedProfilesData = mapProfiles(newMovers, newSitters, eventUsers);

    return {
      queueMatrix,
      consentMatrix,
      movers: mappedProfilesData.movers,
      sitters: mappedProfilesData.sitters,
      at: serverTimestamp()
    };
  } else {
    throw new Error("No new changes found to regenerate queue");
  }
};

const mapUserData = (user, tableNumber = null) => ({
  name: user.name || null,
  bio: user.bio || null,
  gender: user.gender || null,
  photoURL: user.photoURL || null,
  userID: user.userID || null,
  phone: user.phone || null,
  allowTextNotifications: user.allowTextNotifications || false,
  ...(tableNumber !== null && { table: tableNumber }),
});


//helper functions

const createMatrices = (eventUsers, sitters, movers) => {
  // Create the consent matrix based on consents, sitters, and movers
  const removeUnmatchedUsers = createMatrix.removeUnmatchedUsers(eventUsers, sitters, movers);
  const matchedAttendees = createMatrix.createConsentMatrix(eventUsers, removeUnmatchedUsers.sitters, removeUnmatchedUsers.movers);

  // Flatten the consent matrix for easier processing
  const consentMatrix = createMatrix.flattenMatrix(matchedAttendees.consentMatrix);

  // Remove duplicates to generate queue data
  const queueData = createMatrix.generateQueueFromConsent(matchedAttendees.consentMatrix);

  // Flatten the queue data to generate the queue matrix
  const queueMatrix = createMatrix.flattenMatrix(queueData);

  return { consentMatrix, queueMatrix, matchedAttendees };
};


const mapProfiles = (movers, sitters, consents,) => {
  // Map mover IDs to their consent data
  const moversData = movers.map(id => consents.find(u => u.userID === id));

  // Map sitter IDs to their consent data
  const sittersData = sitters.map(id => consents.find(u => u.userID === id));

  // Map mover profiles
  const mappedMovers = movers.map((userID) => {
    const user = moversData.find(user => user.userID === userID);
    return user ? mapUserData(user) : null;
  });

  // Map sitter profiles with table numbering
  const mappedSitters = sitters.map((userID, index) => {
    const user = sittersData.find(user => user.userID === userID);
    const tableNumber = index + 1;
    return user ? mapUserData(user, tableNumber) : null;
  });

  return { movers: mappedMovers, sitters: mappedSitters };
};