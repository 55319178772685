import { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  QrCodeIcon,
  Cog6ToothIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ClipboardIcon,
  QueueListIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { GlobalContext } from "./context/GlobalContext";
import Api from "./api/api";
import VendorDropdown from "./vendorDropdown";
import { VendorProvider } from "./context/VendorContext";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
const defaultNavigation = [
  { name: "Dashboard", href: "/home", icon: HomeIcon, current: null },
  {
    name: "Users",
    href: "/users",
    icon: UsersIcon,
    current: null,
    isSuperAdmin: true,
  },

  {
    name: "Events",
    href: "/events",
    icon: ChatBubbleLeftRightIcon,
    current: null,
  },
  { name: "Orders", href: "/orders", icon: QueueListIcon, current: null },
  { name: "Menu", href: "/menu", icon: ClipboardIcon, current: null },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(defaultNavigation);
  const { globalState } = useContext(GlobalContext);
  const [showQR, setShowQR] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const filteredNavigation = defaultNavigation.filter(
      (item) => !item.isSuperAdmin || globalState.isSuperAdmin
    );

    setNavigation(() =>
      filteredNavigation.map((item) => ({
        ...item,
        current: currentPath === item.href,
      }))
    );
  }, [globalState.isSuperAdmin]);


  const onQRClick = () => {
    setShowQR(!showQR);
  }
  return (
    <>
      <div className="bg-slate-100 min-h-screen">
        <Dialog
          className="relative z-50 lg:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    className="h-16 w-auto"
                    src="/gist_logo.jpg"
                    alt="gist"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-primaryDark text-white"
                                  : "text-white hover:bg-primaryDark hover:text-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-white group-hover:text-white",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>

                    <li className="mt-auto">
                      <a
                        href="/settings"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-primaryDark hover:text-white"
                      >
                        <Cog6ToothIcon
                          className="h-6 w-6 shrink-0 text-white group-hover:text-white"
                          aria-hidden="true"
                        />
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img className="h-16 w-auto" src="/gist_logo.jpg" alt="gist" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-primaryDark text-white"
                              : "text-white hover:bg-primaryDark hover:text-white",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-white group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-primaryDark hover:text-white"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-white group-hover:text-white"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex flex-1 mt-2">
                {globalState.isSuperAdmin}
                {globalState.isSuperAdmin && (
                  <>
                    <VendorProvider>
                      <VendorDropdown />
                    </VendorProvider>
                    {/* <Link
                      to="/admin/users"
                      target="_blank"
                      className="flex items-center text-sm font-semibold ml-4 text-primary hover:text-primaryDark"
                    >
                      Superadmin
                    </Link> */}
                  </>
                )}
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {
                  globalState.qrCodeUrl && (
                    <button
                      type="button"
                      className="-m-2.5 p-2.5 text-gray-500 hover:text-gray-400"
                      onClick={onQRClick}
                    >
                      <span className="sr-only">View notifications</span>
                      <QrCodeIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                  )
                }

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    <span className="flex items-center">
                      <span
                        className="text-sm font-semibold leading-6 text-gray-900 w-0 md:w-auto truncate"
                        aria-hidden="true"
                      >
                        {globalState.vendor
                          ? globalState.vendor.name
                          : "New Vendor"}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <button
                        onClick={Api.signOut}
                        className="block px-3 py-1 text-sm leading-6 text-gray-900"
                      >
                        Log out
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
            {
              showQR && <QRComponent qrCodeUrl={globalState.qrCodeUrl} hideQR={onQRClick} />
            }
          </main>
        </div>
      </div>
    </>
  );
}


function QRComponent({ qrCodeUrl, hideQR }) {


  // Note: In a real implementation, you would use a QR code library
  // For now, we'll show a placeholder and the URL text
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center" onClick={hideQR}>
      <div className="w-3/4 h-1/2 max-w-96 max-h-96 rounded-md shadow-lg   bg-stone-50 p-4 flex flex-col items-center justify-center ">
        <h1 className="text-lg font-semibold pb-4">QR Code to Join</h1>
        <QRCode
          value={qrCodeUrl}
          size={200} // Change size
          bgColor="#FFFFFF" // Background color
          fgColor="#000000" // QR code color
          level="H" // Error correction level: L, M, Q, H
        />
      </div>


    </div>
  );
}