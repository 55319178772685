import React, { useState } from "react";
import CardTable from "./CardTable";

const MutualMatchQueueTable = ({ activeSession = {}, currentQueue = {} }) => {
  const { movers = [], sitters = [], queueMatrix = {} } = currentQueue;

  // Prepare data for sitters and movers
  const allSitters = createTableData(sitters, queueMatrix, true);
  const allMovers = createTableData(movers, queueMatrix, false);
  let restOfMovers = [...allMovers];

  // Render the grid
  return (
    <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-3 grid-cols-2 card-table-space text-white">
      {/* Render matched sitters and movers */}
      {allSitters.map((sitter, index) => {
        const matchedMover = findMoverForSitter(index, activeSession.currentRound, queueMatrix, movers);
        // If a mover was found, remove it from the allMovers
        if (matchedMover) {
          restOfMovers.splice(restOfMovers.findIndex(mover => mover.userID === matchedMover.userID), 1);
        }
        return (
          <CardTable
            key={`sitter_${index}`}
            woman={sitter}
            man={matchedMover}
            table={sitter.table}
            activeSession={activeSession}
          />
        );
      })}

      {/* Render unmatched movers */}
      {restOfMovers.map((mover) => (
        <CardTable
          key={`mover_${mover.table}`}
          man={mover}
          woman={null}
          table={mover.table}
          activeSession={activeSession}
        />
      ))}
    </div>
  );
};

export default MutualMatchQueueTable;

// Creates data for either movers or sitters with matched status
const createTableData = (group, queueMatrix, isSitter) => {
  const matchedSet = new Set(
    Object.keys(queueMatrix).map((key) => Number(key.split('_')[isSitter ? 1 : 0]))
  );

  return group.map((person, index) => ({
    ...person,
    table: index + 1,
    matched: matchedSet.has(index),
  }));
};

// Finds the mover for a given sitter in a specific round
const findMoverForSitter = (sitterIndex, currentRound = 0, queueMatrix, movers) => {
  const adjustedRound = currentRound <= 1 ? 0 : currentRound - 1;

  const key = Object.keys(queueMatrix).find((k) => {
    const [round, sitter] = k.split('_').map(Number);
    return round === adjustedRound && sitter === sitterIndex;
  });

  return key ? movers[queueMatrix[key]] : null;
};