import { createContext, useContext, useEffect, useState } from "react";
import EventAPI from "../api/EventApi";

const EventStreamChatContext = createContext();

export const EventStreamChatProvider = ({ eventID, children }) => {
  const [mensChat, setMensChat] = useState([]);
  const [womensChat, setWomensChat] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!eventID) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const subCollectionNames = ["moversChat", "sittersChat"];
    const timestamp = new Date(0); // Use a proper timestamp if needed

    const unsubscribes = subCollectionNames.map((subCollectionName) => {
      return EventAPI.getEventStreamingWithGroupChatData(
        eventID,
        subCollectionName,
        timestamp,
        (data) => {
          if (subCollectionName === "moversChat") {
            setMensChat(data.moversChat || []);
          } else if (subCollectionName === "sittersChat") {
            setWomensChat(data.sittersChat || []);
          }
          setLoading(false);
        }
      );
    });

    return () => {
      unsubscribes.forEach((unsub) => unsub && unsub());
    };
  }, [eventID]);

  return (
    <EventStreamChatContext.Provider value={{ mensChat, womensChat, loading }}>
      {children}
    </EventStreamChatContext.Provider>
  );
};

export const useEventStreamChat = () => useContext(EventStreamChatContext);
