import React, { useState } from "react";
import ImageModal from "./ImageModal";

const CardTable = ({ table, woman, man, activeSession }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const handleShowImage = (user) => {
    if (!user) return;
    setSelectedUser(user);
    setShowModal(true);
  };

  const isMissingPartner = woman?.name == undefined || man?.name == undefined;
  const isOnline = (user) => {
    const isUserActive = activeSession?.activeUsers?.indexOf(user?.userID) >= 0 || false;
    return (
      isUserActive && (
        <div className="inline-block align-text-top w-3 h-3  bg-lime-400 rounded-full animate-pulse my-1"></div>
      )
    );
  };
  return (
    <>
      <div
        className={`card-table bg-primary  ${isMissingPartner ? "!bg-[#b5b7bb]" : ""
          }`}
      >
        <div
          className="name font-medium  text-center text-nowrap text-ellipsis w-full overflow-hidden"
          onClick={() => handleShowImage(woman)}
        >
          {isOnline(woman)} {woman?.name || "\u00A0"}
        </div>
        <div className="table-number font-bold">{woman?.table || "-"}</div>
        <div
          className="name font-medium text-center text-nowrap text-ellipsis w-full overflow-hidden"
          onClick={() => handleShowImage(man)}
        >
          {isOnline(man)} {man?.name || "\u00A0"}
        </div>
      </div>
      {showModal && (
        <ImageModal
          name={selectedUser?.name}
          avatarUrl={selectedUser?.photoURL}
          setOpen={setShowModal}
        />
      )}
    </>
  );
};

export default CardTable;