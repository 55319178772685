import React, { useState, useEffect } from "react";
import { fetchUsersWithIDs } from "./utils";
import { db } from "../firebase";
import Loader from "../components/Loader";

export const Waitlist = ({
  lateComers,
  eventUsersProfile: initialEventUsersProfile,
}) => {
  const [eventUsersProfile, setEventUsersProfile] = useState(
    initialEventUsersProfile
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      if (eventUsersProfile.length === 0) {
        setIsLoading(true);
        try {
          const users = await fetchUsersWithIDs(
            db,
            Object.values(lateComers).flat()
          );
          setEventUsersProfile(users);
        } catch (err) {
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUsers();
  }, []);

  const renderList = (genderType) => (
    <ul className="w-1/3 text-sm text-center font-medium bg-white border border-gray-200 rounded-lg">
      <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg text-white bg-yellow-500">
        {genderType}
      </li>
      {lateComers[genderType.toLowerCase()]?.map((u, i) => {
        const user = eventUsersProfile.find((up) => up.userID === u);
        return (
          <li
            key={i}
            className="w-full px-4 py-2 border-b border-gray-200 text-slate-700 text-nowrap text-ellipsis overflow-hidden"
          >
            {i + 1}. {user?.name || u}
          </li>
        );
      })}
    </ul>
  );

  if (isLoading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex gap-4">
      {renderList("Movers")}
      {renderList("Sitters")}
    </div>
  );
};
