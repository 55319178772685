export function createConsentMatrix(data, sitters, movers, matchType = 'mutual') {
  // Create a map for quick lookup of consents
  const consentMap = new Map(
    data
      .filter(u => u.userID && u.consents)
      .map(function (item) {
        return [item.userID, new Set(item.consents)];
      })
  );

  // Initialize the matrix with nulls
  const matrix = movers.map(function () {
    return new Array(sitters.length).fill(null);
  });

  // Fill the matrix
  movers.forEach(function (mover, moverIndex) {
    sitters.forEach(function (sitter, sitterIndex) {
      const moverConsents = consentMap.get(mover);
      const sitterConsents = consentMap.get(sitter);

      switch (matchType.toLowerCase()) {
        case 'mutual':
          // Both users must consent to each other
          if (moverConsents &&
            sitterConsents &&
            moverConsents.has(sitter) &&
            sitterConsents.has(mover)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        case 'movers':
          // Only mover's consent is required
          if (moverConsents && moverConsents.has(sitter)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        case 'sitters':
          // Only sitter's consent is required
          if (sitterConsents && sitterConsents.has(mover)) {
            matrix[moverIndex][sitterIndex] = moverIndex;
          }
          break;

        default:
          throw new Error('Invalid matchType. Use "mutual", "mover", or "sitter"');
      }
    });
  });

  return { consentMatrix: matrix, sitters, movers };
}

export function matrixDiff(oldMatrix, newMatrix) {
  oldMatrix = adjustColumns(oldMatrix, newMatrix);
  const result = [];

  // Determine the number of rows to iterate through
  const maxRows = Math.max(oldMatrix.length, newMatrix.length);

  for (let i = 0; i < maxRows; i++) {
    const oldRow = oldMatrix[i] || [];
    const newRow = newMatrix[i] || [];
    const maxCols = Math.max(oldRow.length, newRow.length);

    let rowDiffers = false;
    const diffRow = new Array(maxCols).fill(null);

    for (let j = 0; j < maxCols; j++) {
      if (i >= oldMatrix.length || j >= oldRow.length) {
        // New row or column added
        diffRow[j] = newRow[j];
        rowDiffers = true;
      } else if (i >= newMatrix.length || j >= newRow.length) {
        // Row or column removed
        diffRow[j] = null;
        rowDiffers = true;
      } else if (oldRow[j] !== newRow[j]) {
        // Value changed
        diffRow[j] = newRow[j];
        rowDiffers = true;
      }
    }

    if (rowDiffers) {
      result.push(diffRow);
    }
  }

  return result;
}

export function flattenMatrix(matrix) {
  const flattenedData = {};

  matrix.forEach((row, rowIndex) => {
    row.forEach((value, colIndex) => {
      if (value !== null) {
        flattenedData[`${rowIndex}_${colIndex}`] = value;
      }
    });
  });

  return flattenedData;
}

export function reconstructMatrix(flattenedMatrix) {
  const reconstructedArray = [];

  Object.entries(flattenedMatrix).forEach(([key, value]) => {
    const [row, col] = key.split("_").map(Number);

    if (!reconstructedArray[row]) {
      reconstructedArray[row] = [];
    }

    reconstructedArray[row][col] = value;
  });

  // Fill in any missing values with null
  const maxRow = Math.max(
    ...Object.keys(flattenedMatrix).map((k) => parseInt(k.split("_")[0]))
  );
  const maxCol = Math.max(
    ...Object.keys(flattenedMatrix).map((k) => parseInt(k.split("_")[1]))
  );

  for (let i = 0; i <= maxRow; i++) {
    if (!reconstructedArray[i]) {
      reconstructedArray[i] = [];
    }
    for (let j = 0; j <= maxCol; j++) {
      if (reconstructedArray[i][j] === undefined) {
        reconstructedArray[i][j] = null;
      }
    }
  }

  return reconstructedArray;
}

export function removeUnmatchedUsers(consentData, sitters, movers) {
  // Helper function to check if two users have a mutual match
  const hasMutualMatch = (user1, user2) => {
    const user1Data = consentData.find(u => u.userID === user1);
    const user2Data = consentData.find(u => u.userID === user2);
    return user1Data && user2Data &&
      user1Data.consents.includes(user2) &&
      user2Data.consents.includes(user1);
  };

  // Find mutual matches
  const matchedSitters = sitters.filter(sitter =>
    movers.some(mover => hasMutualMatch(sitter, mover))
  );

  const matchedMovers = movers.filter(mover =>
    sitters.some(sitter => hasMutualMatch(mover, sitter))
  );

  return {
    sitters: matchedSitters,
    movers: matchedMovers
  };
}
export function adjustColumns(oldMatrix, newMatrix) {
  // Get the new column count
  const newColCount = newMatrix[0].length;

  // Map through old matrix and just extend each row
  return oldMatrix.map((row) => {
    // Create new row with existing values and pad with nulls
    const newRow = [...row];
    while (newRow.length < newColCount) {
      newRow.push(null);
    }
    return newRow;
  });
}

export function generateQueueFromConsent(matrix) {
  const numCols = matrix[0].length;
  const columns = Array.from({ length: numCols }, () => []);
  const elementCounts = {};

  // Step 1: Collect elements and count occurrences
  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < matrix.length; row++) {
      const element = matrix[row][col];
      if (element !== null && element !== undefined) {
        columns[col].push(element);
        elementCounts[element] = (elementCounts[element] || 0) + 1;
      }
    }
  }

  // Step 2: Determine minimal number of rows needed
  let numRows = Math.max(...Object.values(elementCounts));

  // Step 3: Initialize data structures
  let outputMatrix = Array.from({ length: numRows }, () =>
    Array(numCols).fill(null)
  );
  const rowSets = Array.from({ length: numRows }, () => new Set());

  // Step 4: Assign elements to rows
  for (let col = 0; col < numCols; col++) {
    const elements = columns[col];
    for (const element of elements) {
      let assigned = false;

      // Try to assign to an existing row
      for (let row = 0; row < outputMatrix.length; row++) {
        if (!rowSets[row].has(element) && outputMatrix[row][col] === null) {
          outputMatrix[row][col] = element;
          rowSets[row].add(element);
          assigned = true;
          break;
        }
      }

      // If not assigned, increase the number of rows
      if (!assigned) {
        outputMatrix.push(Array(numCols).fill(null));
        rowSets.push(new Set());
        const newRow = outputMatrix.length - 1;
        outputMatrix[newRow][col] = element;
        rowSets[newRow].add(element);
      }
    }
  }

  // Step 5: Remove empty rows
  outputMatrix = outputMatrix.filter((row) =>
    row.some((cell) => cell !== null)
  );

  return outputMatrix;
}