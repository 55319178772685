// QueueTable.js
import React, { useState } from "react";
import CardTable from "./CardTable";

const QueueTable = ({
  initialGroupA = [],
  initialGroupB = [],
  activeSession = [],
}) => {
  return (
    <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-3 grid-cols-2 card-table-space text-white">
      {Array.from({
        length: Math.max(initialGroupA.length, initialGroupB.length),
      }).map((_, index) => {
        // if (initialGroupA[index] == null || initialGroupB[index] == null) return null;
        return (
          <CardTable
            man={initialGroupA[index]}
            woman={initialGroupB[index]}
            table={index + 1}
            key={index}
            activeSession={activeSession}
          />
        );
      })}
    </div>
  );
};

export default QueueTable;